import { render, staticRenderFns } from "./specialInfo.vue?vue&type=template&id=27e9bf34&scoped=true"
import script from "./specialInfo.vue?vue&type=script&lang=js"
export * from "./specialInfo.vue?vue&type=script&lang=js"
import style0 from "./specialInfo.vue?vue&type=style&index=0&id=27e9bf34&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e9bf34",
  null
  
)

export default component.exports