<template>
	<div class="specialInfo">
		<div class="info_Header">
			<div class="info_H_Con">
				<div class="crumbs">
					<span class="current">当前位置：</span>
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item><span class="cu" @click="toHome">首页</span></el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/specialList' }">专题列表</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
			<div class="Header_Details">
				<img :src="courseDetail.zt_thumb" class="coursesImg" />
				<div class="Header_Right">
					<div class="Header_Tit">{{courseDetail.zt_name}}</div>
					<div class="Header_DA">
						<span class="Header_duration">行业：{{courseDetail.dic_name.toString()}}</span>
					</div>
					<div class="Header_DA">
						<span class="Header_duration">总课程：{{courseDetail.zt_count}}门，共{{courseDetail.zt_sumtime}}学时</span>
					</div>
					<div class="Header_sign">
						<img src="@/views/images/time_icon.png" />
						<span>报名日期：{{con_sign_time}}</span>
						<!-- <span>报名日期：请耐心等候通知</span> -->
					</div>
					<div class="Header_train">
						<img src="@/views/images/time_icon.png" />
						<span>培训日期：{{con_exam_time}}</span>
						<!-- <span>培训日期：请耐心等候通知</span> -->
					</div>
					<div v-if="isBuy">
						<div v-if="courseDetail.bofang">
							<div class="Header_LH">
								<span class="Header_Hour">正在学习：{{courseDetail.bofang.cou_name}}</span>
							</div>
							<p class="Header_Keep" @click="gotoVideoPlay">继续学习</p>
						</div>
						<p v-else class="Header_Keep" @click="gotoVideoPlay">开始学习</p>
					</div>
					<div v-else>
						<div class="Header_Price">￥<span>{{courseDetail.zt_price}}</span></div>
						<p class="Header_Learn" :class="{ash:!canSignUp}" @click="learnNow">立即报名</p>
					</div>
				</div>
			</div>			
		</div>
		<div class="courseCon">
			<div class="courseMenu">
				<div class="courseMenu_tit">
					<span :class="{active: MenuChose == 'jj'}" @click="tabChange('jj')">专题简介</span>
					<span :class="{active: MenuChose == 'ml'}" @click="tabChange('ml')">专题课程</span>
				</div>
				<div v-if="MenuChose == 'jj'" class="bjList">
					<div ref="Intro_des" v-html="courseDetail.zt_description"></div>
				</div>
				<div v-if="MenuChose == 'ml'" class="mlList">
					<div class="mlItem" v-for="(item,index) in courseDetail.cou_list" :key="index" @click="gotoVideoPlays(item)">
						<img :src="item.cou_thumb" class="cou_thumb"/>
						<div>
							<div class="cou_name">{{item.cou_name}}</div>
							<div class="sumtime_lec">
								<img src="@/views/images/time.png"/>
								<span>课时：{{item.cou_sumtime}}</span>
								<span class="line">|</span>
								<img src="@/views/images/teacher.png"/>
								<span>授课老师：{{item.lec_list.toString()}}</span>
							</div>
							<div class="cou_description">{{item.cou_description}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from '/src/views/js/bus.js';
	import { Loading, Rate} from 'element-ui';
	export default {
	    data() {
			return {
				loading:false,
				isBuy:false,//是否购买
				courseDetail:{
					dic_name:[]
				},//课程详情
				con_sign_time:"",//报名时间
				con_exam_time:"",//培训时间
				isOpen:false,//是否展开简介
				moreShow:false,//是否显示更多
				MenuChose:"jj",//课程目录或者课程笔记
				course_type:"",//课程类别
				mlList:[],//课程目录列表
				lecturerList:[],//讲师列表
				lecturerInfo:{},//当前讲师信息
				isOpendes:false,//是否展开讲师简介
				moreShowdes:false,//是否显示讲师简介更多
				cuurlecturer:0,//当前第几个讲师
				relevantCourse:[],//相关课程列表
				pageSize:10,//每页条数
				NotesList:[],//笔记列表
				total:0,//笔记总数
				canSignUp:false,//是否在报名范围内
				con_sign_time_arr:[],//报名时间
				isOrderStatus:0,//是否在订单中
				isCarts:0,//是否在购物车中
				are_code:"",//城市code
			}
	    },
		methods:{
			//跳转首页
			toHome(){
				this.$router.push({
					path: "/"
				});
				Bus.$emit('busIsHome', true);//是否为省平台
				Bus.$emit('busmenuChose', "");
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//获取专题详情
			getCourseDetail(){
				var that = this
				this.$http.post(this.PublicJs.publicPath + "/api/website/Zt/getDetail", {
					zt_id:this.$route.query.zt_id,
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.courseDetail = response.data.data;
						this.isBuy = response.data.data.is_buy;
						if(this.courseDetail.zt_sign_time){
							var con_sign_time = this.courseDetail.zt_sign_time.split(",")
							this.con_sign_time = con_sign_time[0] + " 至 " + con_sign_time[1];
							this.canSignUp = this.PublicJs.isDuringDate(con_sign_time[0],con_sign_time[1])
						}
						if(this.courseDetail.zt_exam_time){
							var con_exam_time = this.courseDetail.zt_exam_time.split(",")
							this.con_exam_time = con_exam_time[0] + " 至 " + con_exam_time[1]
						}
						this.isOrderStatus = this.courseDetail.isOrderStatus
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击展开收起
			openOrHide(){
				this.isOpen = !this.isOpen;
			},
			//点击展开收起
			openOrHidedes(){
				this.isOpendes = !this.isOpendes;
			},
			//点击切换
			tabChange(type){
				this.MenuChose = type;
			},
			//笔记分页
			changPage(val){
				//获取课程笔记
				this.getNotes(val)
			},
			//立即学习
			learnNow(){
				if(window.sessionStorage.getItem('token')){
					var that = this;
					if(this.canSignUp){
						if(this.isOrderStatus == 0 || this.isOrderStatus == 1 || this.isOrderStatus == 2 || this.isOrderStatus == 5){
							this.$http.post(this.PublicJs.publicPath + "/api/student.Order/beforeAddZt.html", {
								zt_id:this.$route.query.zt_id,
								token:window.sessionStorage.getItem('token')
							}, {
								emulateJSON: true
							}).then(response => {
								if(response.data.code == 1){
									this.$router.push({
										path: "/confirmOrderZt",
										query: {
											zt_id:this.$route.query.zt_id
										}
									});
								}else if(response.data.code == 2){
									this.$confirm(response.data.data.list.toString() + '您已经购买过，将为您排除这些课程进行下单。', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {
										this.$router.push({
											path: "/confirmOrder",
											query: {
												course_id:this.PublicJs.Encrypt(response.data.data.buy_course_id.toString()),
												isCarts:this.PublicJs.Encrypt(this.isCarts)
											}
										});
									}).catch(() => {
									           
									});
								}else{
									this.$message({
										message: response.data.msg,
										type: 'warning',
										showClose: true
									});
								}
							}, response => {
								if(response.status == 401){
									var that = this;
									this.$message({
										message: "您的账号在别处登录，请重新登录",
										type: 'warning',
										showClose: true
									});
									window.sessionStorage.removeItem('user_id')
									window.sessionStorage.removeItem('token')
									window.sessionStorage.removeItem('userinfo')
									setTimeout(function(){
										that.$router.go(0)
									},2000); 
									
								}
							});
						}else{
							this.$message({
								message: "该课程已在订单中，请前往订单中支付",
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								that.$router.push({
									path: "/order"
								});
							},1500);
						}					
					}else{
						var curDate = new Date(),
						beginDate = new Date(this.con_sign_time_arr[0]),
						endDate = new Date(this.con_sign_time_arr[1]);
						if(curDate < beginDate){
							this.$message({
								message: "该年度报名尚未开始",
								type: 'warning',
								showClose: true
							});
						}else if(curDate > endDate){
							this.$message({
								message: "该年度报名已结束，如有疑问请联系客服",
								type: 'warning',
								showClose: true
							});
						}
					}
				}else{
					this.$parent.Callloginpopup();
				}
			},
			//继续学习
			gotoVideoPlay(){
				if(this.courseDetail.bofang){
					this.$router.push({
						path: "/videoPlay",
						query: {
							course_id:this.PublicJs.Encrypt(this.courseDetail.bofang.stti_course_id),
							source:this.PublicJs.Encrypt("specialInfo"),
							cuur_cow_id:this.PublicJs.Encrypt(this.courseDetail.bofang.stti_courseware_id),
							zt_id:this.$route.query.zt_id
						}
					});
				}else{
					this.$router.push({
						path: "/videoPlay",
						query: {
							course_id:this.PublicJs.Encrypt(this.courseDetail.cou_list[0].cou_id),
							source:this.PublicJs.Encrypt("specialInfo"),
							zt_id:this.$route.query.zt_id
						}
					});
				}				
			},
			//跳转学习
			gotoVideoPlays(item){
				if(window.sessionStorage.getItem('token')){
					window.sessionStorage.setItem('isHome',false)
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("course"))
					this.$router.push({
						path: "/courseInfo",
						query: {
							course_id:this.PublicJs.Encrypt(item.cou_id)
						}
					});
				}else{
					this.$message({
						message: "请先登录！",
						type: 'warning',
						showClose: true
					});
				}
			},
			//反转义
			html_decode(str){
				var s = ""; 
				if (str.length == 0) return ""; 
				s = str.replace(/&amp;/g, "&"); 
				s = s.replace(/&lt;/g, "<"); 
				s = s.replace(/&gt;/g, ">"); 
				s = s.replace(/&nbsp;/g, " "); 
				s = s.replace(/&#39;/g, "\'"); 
				s = s.replace(/&quot;/g, "\""); 
				s = s.replace(/<br\/>/g, "\n"); 
				return s; 
			},
		},
		created() {
			//获取专题详情
			this.getCourseDetail();
		},
		watch: {
			'$route'(to, from) {
				if (to.query) {
					//获取专题详情
					this.getCourseDetail();
				}
			}
		},
	}
</script>

<style scoped="scoped">
	.info_Header{
		height: 432px;
		width: 100%;
		background-color: #FFF;
		box-shadow: 0px 1px 6px 0px rgba(153,153,153,0.18); 
		border-top: 1px solid #F9F9F9;
	}
	.info_H_Con{
		width: 1200px;
		margin: 0 auto;
		padding-top: 34px;
	}
	.crumbs {
	    font-size: 14px;
	}
	.cu{
		cursor: pointer;
	}
	.current {
	    vertical-align: top;
	    line-height: 1;
	}
	.el-breadcrumb {
	    display: inline-block;
	    vertical-align: top;
	}
	/* 头部 */
	.Header_Details{
		position: relative;
		padding-left: 565px;
		width: 1200px;
		margin: 0 auto;
		margin-top: 30px;
	}
	.coursesImg{
		position: absolute;
		top: 0;
		left: 0;
		width: 524px;
		height: 314px;
	}
	.Header_Right{
		position: relative;
		width: 100%;
		height: 314px;
	}
	.Header_Tit{
		font-size: 20px;
		color: #333;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		margin-bottom: 27px;
	}
	.Header_TY{
		margin-bottom: 23px;
	}
	.Header_Type{
		width: 46px;
		height: 24px;
		background: #ffac3d;
		border-radius: 4px;
		font-size: 12px;
		color: #FFF;
		line-height: 24px;
		text-align: center;
		display: inline-block;
		margin-right: 10px;
	}
	.Header_Year{
		color: #999;
		font-size: 16px;
	}
	.Header_rate{
		display: inline-block;
		vertical-align: top;
	}
	.Header_duration{
		font-size: 14px;
		color: #999;
		vertical-align: middle;
	}
	.Header_DA{
		line-height: 30px;
		font-size: 0;
		margin-bottom: 15px;
	}
	.Header_Audition{
		display: inline-block;
		vertical-align: middle;
		font-size: 0;
		line-height: 30px;
		cursor: pointer;
		margin-left: 30px;
	}
	.Header_Audition img{
		vertical-align: middle;
		margin-right: 10px;
	}
	.Header_Audition span{
		vertical-align: middle;
		font-size: 14px;
		color: #333;
	}
	.Header_sign,.Header_train{
		font-size: 0;
		line-height: 30px;
	}
	.Header_sign img,.Header_train img{
		vertical-align: middle;
		margin-right: 8px;
	}
	.Header_sign span,.Header_train span{
		font-size: 14px;
		color: #999;
		vertical-align: middle;
	}
	.Header_sign{
		margin-bottom: 11px;
	}
	.Header_train{
		margin-bottom: 50px;
	}
	.Header_Price{
		font-size: 20px;
		color: #ff483b;
	}
	.Header_Price span{
		font-size: 40px;
	}
	.Header_Learn,.Header_tocart,.Header_Keep{
		width: 120px;
		height: 44px;
		border-radius: 22px;
		text-align: center;
		line-height: 44px;
		font-size: 14px;
		font-weight: bold;
		position: absolute;
		bottom:0;
		cursor: pointer;
	}
	.Header_Learn{
		background: #ff483b;
		color: #FFF;
		right: 140px;
	}
	.Header_tocart{
		background: #fee7e7;
		color: #ff483b;
		right: 0;
	}
	.Header_Learn.ash,
	.Header_tocart.ash{
		background: #C0C0C0;
		color: #FFF;
	}
	.Header_Learn:hover,
	.Header_tocart:hover{
		opacity: 0.8;
	}
	.Header_Keep{
		right: 0;
		color: #FFF;
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
	}
	.Header_Keep:hover{
		background: linear-gradient(0deg, rgba(77,110,242,0.70) 100%,rgba(77,110,242,0.90) 0%);
	}
	.Header_Learned{
		font-size: 14px;
		color: #ff0000;
		margin-right: 40px;
	}
	.Header_Hour{
		font-size: 14px;
		color: #333;
	}
	/* 内容 */
	.courseCon{
		width: 1200px;
		margin: 0 auto;
		padding-top: 20px;
		padding-bottom: 47px;
	}
	.courseCon::after{
		content: "";
		display: block;
		clear: both;
	}
	.courseCon_L{
		width: 850px;
		float: left;
	}
	.courseCon_R{
		width: 328px;
		float: right;
	}
	.courseIntro{
		width: 100%;
		box-shadow: 0px 4px 7px 0px rgba(51,51,51,0.13); 
		background-color: #FFF;
		padding: 15px 30px;
	}
	.Intro_tit{
		height: 28px;
		background-image: url(../images/tit_icon.png);
		background-repeat: no-repeat;
		background-position: center left;
		line-height: 28px;
		font-size: 16px;
		color: #333;
		font-weight: bold;
		padding-left: 16px;
		position: relative;
	}
	.Intro_des{
		font-size: 14px;
		color: #333;
		line-height: 24px;
		padding-top: 10px;
		overflow: hidden;
	}
	.moreIndustry{
		/* display: inline-block;
		position: absolute;
		right: 0;
		top: 0; */
		cursor: pointer;
		font-size: 14px;
		font-weight: initial;
		color: #999;
		text-align: center;
		padding-top: 5px;
	}
	.moreIndustry i{
		margin-left: 5px;
	}
	.courseMenu{
		width: 100%;
		box-shadow: 0px 4px 7px 0px rgba(51,51,51,0.13); 
		background-color: #FFF;
		margin-top: 20px;
		padding: 30px;
	}
	.courseMenu_tit{
		height: 40px;
	}
	.courseMenu_tit span{
		display: inline-block;
		text-align: center;
		line-height: 20px;
		font-size: 18px;
		color: #333;
		cursor: pointer;
		margin-right: 58px;
	}
	.courseMenu_tit span.only{
		font-weight: bold;
	}
	.courseMenu_tit span.active{
		color: #F74937;
		font-weight: bold;
		position: relative;
	}
	.courseMenu_tit span.active::after{
		width: 74px;
		height: 4px;
		background: linear-gradient(0deg,#f39c90 0%, #f26954 100%);
		border-radius: 2px;
		position: absolute;
		content: "";
		left: -2px;
		bottom: -8px;
	}
	.mlList{
		
	}
	.mlItem{
		padding: 20px 0;
		border-bottom: 1px solid #EEEEEE;
		display: flex;
		cursor: pointer;
	}
	.mlItem:last-child{
		border-bottom: none;
	}
	.cou_thumb{
		width: 226px;
		height: 136px;
		border-radius: 6px;
		margin-right: 20px;
	}
	.cou_name{
		font-size: 18px;
		font-weight: 700;
		color: #333333;
		line-height: 1;
		margin-bottom: 20px;
	}
	.sumtime_lec{
		font-size: 14px;
		color: #333333;
		line-height: 1;
		display: flex;
		align-items: center; 
	}
	.sumtime_lec img{
		margin-right: 10px;
	}
	.line{
		margin: 0 20px;
	}
	.cou_description{
		margin-top: 23px;
		font-size: 14px;
		font-weight: 400;
		color: #333333;
		line-height: 24px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
</style>